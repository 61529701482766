import { useCallback, useEffect, useRef, useState } from 'react'
import './styles.scss'
import axios from 'axios';
import Hls from 'hls.js';
import playIcon from '../../assets/image/player/play.png'
import pauseIcon from '../../assets/image/player/pause.png'
import forwardIcon from '../../assets/image/player/forward.png'
import backwardIcon from '../../assets/image/player/backward.png'
import increaseIcon from '../../assets/image/player/increase.png'
import decreaseIcon from '../../assets/image/player/increase.png'
import fullscreenIcon from '../../assets/image/player/full-screen.svg'
import airplayIcon from '../../assets/image/player/airplay.png'

import { TimeSeekSlider } from 'react-time-seek-slider';
import 'react-time-seek-slider/lib/ui-time-seek-slider.css';

function VideoPlayer({ workoutID, userID, parts }) {

    // const backendURL = 'http://208.113.130.8:4002'
    const backendURL = process.env.REACT_APP_VIDEO_SERVER
    var videoSrc = `${backendURL}public/${workoutID}/playlist/${userID}.m3u8`
    const isSentRequest = useRef(false);
    const isLockSlider = useRef(false);
    const videoRef = useRef(null);
    const timeEnd = useRef(-1)
    const currentPart = useRef(0)
    const difficultyRef = useRef(1)
    const currentPosRef = useRef(0)
    const nextDifficultyRef = useRef(1)
    const completedPartsRef = useRef([])
    const playerWrapperRef = useRef(null);
    const isFocusedSlider = useRef(false)
    const [currentTime, setCurrentTime] = useState(0); // State to track current time
    const difficulties = ['low', 'medium', 'high']
    const [difficulty, setDifficulty] = useState(1)
    const [nextDifficulty, setNextDifficulty] = useState(1)
    const [currentPartData, setCurrentPartData] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showFullScreen, setShowFullScreen] = useState(false)
    const [seeker, setSeeker] = useState(0)

    useEffect(() => {
        initializeHls(videoSrc)
        const video = document.getElementById('video');

        const handleTimeUpdate = () => {
            if (parseInt(video.currentTime) > 0 && isLockSlider.current == false) {
                setCurrentTime(video.currentTime); // Update the currentTime state
            }
        };

        const hideBar = () => {
            window.scrollTo(0, 0);

            setTimeout(() => {
                window.scrollTo(0, window.scrollY + 1);
            }, 1000);
        };

        hideBar();


        if (window.WebKitPlaybackTargetAvailabilityEvent) {

            video.addEventListener('webkitplaybacktargetavailabilitychanged',
                function (event) {
                    var airPlayButton = document.getElementById("airPlayButton");
                    switch (event.availability) {
                        case "available":
                            airPlayButton.hidden = false;
                            airPlayButton.disabled = false;
                            break;
                        case "not-available":
                            airPlayButton.hidden = true;
                            airPlayButton.disabled = true;
                            break;
                    }
                });

            var airPlayButton = document.getElementById("airPlayButton");
            airPlayButton.addEventListener('click', function (event) {
                video.webkitShowPlaybackTargetPicker();
            });

        }

        video.addEventListener('playing', function () {
            setIsPlaying(true)
        })

        video.addEventListener('pause', function () {
            setIsPlaying(false)
        })

        video.addEventListener('timeupdate', handleTimeUpdate);
        window.addEventListener('orientationchange', hideBar);
        // Clean up the event listener when the component unmounts
        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
            window.removeEventListener('orientationchange', hideBar);
        };

    }, [])

    useEffect(() => {

        if (currentTime > 0 && timeEnd.current > 0 && currentTime > timeEnd.current - 3 && !isSentRequest.current) {
            isSentRequest.current = true // Lock this function
            requestNewPart()
        }

    }, [currentTime])

    const initializeHls = (video_src) => {

        let hls = new Hls({
            startPosition: 0,
            autoStartLoad: true
        });
        hls.loadSource(video_src);
        const video = document.getElementById('video');
        hls.attachMedia(video);

        hls.on(Hls.Events.FRAG_PARSED, (event, data) => {

            if (timeEnd.current < data.frag.endDTS) {
                timeEnd.current = data.frag.endDTS  // Update Current part's end point
                console.log(timeEnd.current, 'end')
                isSentRequest.current = false // Unlock requestNewPart function

            }

        });

        return hls

    }

    function isIPhoneSafari() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Check if the device is iPhone and browser is Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        const isIPhone = /iPhone/i.test(userAgent);

        return isSafari && isIPhone;
    }

    function isPortrait() {
        return window.matchMedia("(orientation: portrait)").matches;
    }

    function detectIPhoneSafariPortrait() {
        if (isIPhoneSafari() && isPortrait()) {
            return true
            // Add your logic here (e.g., show alert, modify UI, etc.)
        } else {
            return false
        }
    }

    function getTopPosition() {

        if (detectIPhoneSafariPortrait() == false)
            return '50%';
        else
            return 'calc(50% + 40px)'
    }

    const requestNewPart = () => {
        try {
            if (completedPartsRef.current.length >= 3) return
            const response = axios.post(`${backendURL}save-v2`, {
                nextDifficulty: difficulties[nextDifficulty],
                completedParts: [...completedPartsRef.current, difficulties[difficulty]],
                workoutID,
                userID,
                parts
            });

            completedPartsRef.current = [...completedPartsRef.current, difficulties[difficulty]]
            currentPart.current = currentPart.current + 1 // Update current Part
            setCurrentPartData(currentPartData + 1)
            setDifficulty(nextDifficulty) // set current difficulty
            difficultyRef.current = nextDifficulty
        } catch (error) {
            console.error(error);
        }
    }

    const increase = () => {
        if (difficulty < 2) {
            setNextDifficulty(difficulty + 1)
            nextDifficultyRef.current = difficulty + 1
        }
        else {
            setNextDifficulty(difficulty)
            nextDifficultyRef.current = difficulty
        }

    }

    const decrease = () => {
        if (difficulty > 0) {
            setNextDifficulty(difficulty - 1)
            nextDifficultyRef.current = difficulty - 1
        }
        else {
            setNextDifficulty(difficulty)
            nextDifficultyRef.current = difficulty
        }
    }

    const onClear = async () => {
        try {
            const response = await axios.post(`${backendURL}clear`, {
                workoutID, userID, parts
            });
        } catch (err) {

        }
    }

    useEffect(() => {
        onClear()
    }, [])

    const togglePlayer = () => {

        if (isPlaying) {
            videoRef.current.pause(); // Pause the video if it's playing
        } else {
            videoRef.current.play(); // Play the video if it's paused
        }

    }

    const onForward = useCallback(() => {

        if (currentTime < timeEnd.current - 5) {
            videoRef.current.currentTime = currentTime + 3
        }

    }, [currentTime])

    const onBackward = useCallback(() => {


        if (currentTime > 3) {
            videoRef.current.currentTime = currentTime - 3
        }
    }, [currentTime])

    const getCurrentPart = () => {
        let nextPartID = 0
        if (currentPosRef.current < 40) nextPartID = 0
        else if (currentPosRef.current > 40 && currentPosRef.current < 80) nextPartID = 1
        else if (currentPosRef.current > 80 && currentPosRef.current < 120) nextPartID = 2
        else nextPartID = 3
        return nextPartID
    }

    const onChangeProgress = () => {
        let nextPartID = getCurrentPart()

        if (nextPartID <= currentPart.current) { // if new part is before current part
            return
        }

        let newCompletedParts = [...completedPartsRef.current]
        if (nextPartID > completedPartsRef.current.length) {
            for (var i = completedPartsRef.current.length; i < nextPartID; i++) {
                newCompletedParts.push(difficulties[difficultyRef.current])
            }
        }

        // if we discover new part...
        try {
            isSentRequest.current = true
            isLockSlider.current = true
            const response = axios.post(`${backendURL}save-v2`, {
                nextDifficulty: difficulties[nextDifficultyRef.current],
                completedParts: newCompletedParts,
                workoutID,
                userID,
                parts
            });
            completedPartsRef.current = newCompletedParts
            currentPart.current = nextPartID
            setCurrentPartData(nextPartID)
            setNextDifficulty(difficultyRef.current)
            nextDifficultyRef.current = difficultyRef.current
            /* ------------------Initialize HLS player-------------------- */
            let hls = new Hls({
                startPosition: currentPosRef.current,
                autoStartLoad: true
            });

            var oneTimeFlag = true // For one time, we change slider because this handler is called every switching...
            hls.on(Hls.Events.FRAG_PARSED, (event, data) => {

                if (currentPosRef.current < data.frag.endDTS && timeEnd.current < data.frag.endDTS) {
                    timeEnd.current = data.frag.endDTS
                    console.log(timeEnd.current, 'end')
                    isSentRequest.current = false
                    isLockSlider.current = false
                    if (oneTimeFlag) {
                        console.log(currentPosRef.current, 'seeker')
                        videoRef.current.currentTime = currentPosRef.current
                        video.play()
                        oneTimeFlag = false
                    }
                }
            });

            hls.loadSource(videoSrc);
            const video = document.getElementById('video');
            hls.attachMedia(video);

        } catch (err) {

        }
    }

    const onChangeSeeker = (val) => {

        currentPosRef.current = val
        const video = document.getElementById('video');
        if (val > timeEnd.current && isSentRequest.current == false) { // if scroll bar reaches at new part
            console.log('We are going to new part now...')
            onChangeProgress()
        } else {
            video.currentTime = val
            console.log(val, 'changing...')
        }
        setCurrentTime(val)

    }

    const onFullScreen = () => {
        if (showFullScreen) {
            // handle.exit()
            document.webkitCancelFullScreen();
            setShowFullScreen(false)
        }
        else {
            // handle.enter()
            if (playerWrapperRef.current?.webkitRequestFullScreen) {
                playerWrapperRef.current?.webkitRequestFullScreen();
                setShowFullScreen(true);
            } else if (playerWrapperRef.current?.requestFullscreen) {
                playerWrapperRef.current?.requestFullscreen();
            }
        }
    }

    const onSeeking = (val) => {

        isSentRequest.current = true
        isFocusedSlider.current = true
        setSeeker(val / 1000)
        setCurrentTime(val / 1000)

    }
    const onSeekEnded = () => {

        if (isFocusedSlider.current == false) return
        isSentRequest.current = false
        onChangeSeeker(seeker)
        isFocusedSlider.current = false
    }

    return (
        <>

            <div className='Container'>
                <div className='video-wrapper' ref={playerWrapperRef}>
                    <video id="video" ref={videoRef} autoplay playsInline >

                    </video>
                    <div className='controls'>

                        <div className='controls-wrapper' style={{ top: getTopPosition() }}>
                            <img className='control' src={decreaseIcon} onClick={decrease} />
                            <img className='control' src={backwardIcon} onClick={onBackward} />
                            <img className='control' src={isPlaying ? pauseIcon : playIcon} onClick={togglePlayer} />
                            <img className='control' src={forwardIcon} onClick={onForward} />
                            <img className='control' src={increaseIcon} onClick={increase} />
                        </div>
                        <div className='bottom-player-control' style={{ bottom: isIPhoneSafari() ? '10px' : '40px' }}>
                            <div className='difficulty-container' style={{ background: nextDifficultyRef.current > difficultyRef.current ? 'red' : nextDifficultyRef.current == difficultyRef.current ? 'transparent' : 'blue' }}> </div>
                            <p className='seeker-control'>{parseInt(currentTime) / 60 < 10 ? '0' + parseInt(currentTime / 60) : parseInt(currentTime / 60)}:{parseInt(currentTime) % 60 < 10 ? '0' + parseInt(currentTime) % 60 : parseInt(currentTime) % 60}</p>

                            <div style={{ width: '100%' }} onMouseUp={() => { isFocusedSlider.current = true }} onTouchEnd={() => { isFocusedSlider.current = true }} >
                                <TimeSeekSlider
                                    max={160 * 1000}
                                    currentTime={currentTime * 1000}
                                    hideHoverTime={true}
                                    progress={400}
                                    onSeeking={onSeeking}
                                    onSeekend={onSeekEnded}
                                    offset={0}
                                    secondsPrefix="00:00:"
                                    minutesPrefix="00:"
                                />
                            </div>
                            <p>02:40</p>
                            <img className='airplay-control total-duration-control' src={airplayIcon} id="airPlayButton" hidden disabled />
                            {isIPhoneSafari() == false ? <img className='total-duration-control' src={fullscreenIcon} onClick={onFullScreen} /> : <div style={{ width: 20 }}></div>}
                        </div>

                    </div>
                </div>
                {/* <p> part {currentPartData + 1}, Difficulty {difficulties[difficulty]}</p> */}
            </div >
        </>
    )
}


export default VideoPlayer