import s from "./workout-creation-page.module.scss";
import { useEffect, useRef, useState } from "react";
import { Input } from "../../../components/input/Input";
import { Multiselect, Select } from "../../../components/select-menu";
import sportEquipmentIcon from "../../../assets/image/sport-equipment-icon.png";
import coachIcon from "../../../assets/image/coach.png";
import bodyPartIcon from "../../../assets/image/body-part.png";
import workoutStyleIcon from "../../../assets/image/workout-style-icon.png";
import trueFalseIcon from "../../../assets/image/true_false.png";
import { bodyParts, workoutStyles } from "../../../assets/constants";
import { useDispatch, useSelector } from "react-redux";
import { instructorsSelector } from "../../../store/selectors/instructorsSelector";
import { getInstructorsList } from "../../../store/operations/instructors-operations";
import { getAllEquipmentsList } from "../../../store/operations/equipments-operations";
import { equipmentsSelector } from "../../../store/selectors/equipments-selector";
import { Button } from "../../../components/button/Button";
import WorkoutsAPI from "../../../api/WorkoutsAPI";
import { useAlert } from "../../../hooks/useAlert";
import { Vimeo } from "vimeo";
import videoFileIcon from "../../../assets/image/video-file.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import NO_THUMBNAIL_IMAGE from '../../../assets/image/no-thumbnail-image.jpg'

async function readTextFileAsBlob(file) {
  const response = await fetch(file);
  const blob = await response.blob();

  return blob;
}

const { REACT_APP_VIMEO_CLIENT_ID, REACT_APP_VIMEO_CLIENT_SECRET, REACT_APP_VIMEO_ACCESS_TOKEN } = process.env;

const _baseWorkoutData = {
  title: "",
  difficulty: 1,
  bodyPart: [],
  style: "",
  priority: 10,
  instructor: "",
  calory: 200,
  hr: 60,
  isFitnessTestVideo: false,
  equipments: [],
};

const _basePartData = {
  name: "Part name",
  nodes: [
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "LOW",
    },
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "MEDIUM",
    },
    {
      nodeId: Math.ceil(Math.random() * Date.now()),
      timeStart: "00:00",
      timeEnd: "00:00",
      difficulty: "HIGH",
    },
  ],
  tag: '',
  targetHR: 90,
};

const _basePartsArrayData = [{ ..._basePartData, partId: Date.now() }];

const VimeoAPI = {
  getStatus: async (videoId) => {
    const response = await axios.get(
      `https://api.vimeo.com/videos/${videoId}?fields=transcode.status,status`,
      {
        headers: {
          Authorization: `bearer ${REACT_APP_VIMEO_ACCESS_TOKEN}`,
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      }
    )

    return response;
  },
}

function vimeoVideoURItoVideoID(uri) {
  const [videoId] = uri.match(/[0-9]+/);
  return videoId
}

function pollTranscodingStatus(uri, onSuccess, onError) {
  return async () => {
    const videoId = vimeoVideoURItoVideoID(uri);
    const resposne = await VimeoAPI.getStatus(videoId)
    console.log('resposne', resposne);
    const { transcode, status } = resposne.data;
    const transcodeStatus = transcode?.status;

    if (status === 'available' || transcodeStatus === 'complete') {
      onSuccess();
    } else if (status === 'transcode_starting' || status === 'transcoding' || transcodeStatus === 'in_progress') {
      setTimeout(pollTranscodingStatus(uri, onSuccess, onError), 5000);
    } else {
      onError('Transcoding failed');
    }
  }
}

const prioritizeWhenWatchConnectedOptions = [{ _id: 'true', title: 'True' }, { _id: 'false', title: 'False' }]
const isFitnessTestVideo = [{ _id: 'true', title: 'Test' }, { _id: 'false', title: 'Production' }]
export const CreateWorkoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successAlert, errorAlert] = useAlert();
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [baseWorkoutData, setBaseWorkoutData] = useState(_baseWorkoutData);
  const [isCreatingInProgress, setIsCreatingInProgress] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [videoThumbnail2, setVideoThumbnail2] = useState(null);
  const [defaultThumbnail, setDefaultThumbnail] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailImage2, setThumbnailImage2] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState("0");
  const [innerWorkoutId, setInnerWorkoutId] = useState(null);
  const [vimeoVideoUri, setVimeoVideoUri] = useState(null);
  const [parts, setParts] = useState(_basePartsArrayData);
  const { instructorsList } = useSelector(instructorsSelector);
  const { equipmentsList } = useSelector(equipmentsSelector);
  const tagList = [
    {
      title: 'Arms',
      _id: 1,
    },
    {
      title: 'Legs',
      _id: 2,
    },
    {
      title: 'Chest',
      _id: 3,
    },
    {
      title: 'Core',
      _id: 4,
    },
    {
      title: 'Cardio',
      _id: 5,
    }
  ]
  useEffect(() => {
    dispatch(getInstructorsList(0, 99999));
  }, []);

  useEffect(() => {
    dispatch(getAllEquipmentsList());
  }, []);
  useEffect(() => {
    if (currentStep != 3) return
    const eventSource = new EventSource(process.env.REACT_APP_VIDEO_SERVER + 'progress');

    eventSource.onmessage = (event) => {
      const { progress } = JSON.parse(event.data);
      setProgressPercentage(Math.floor(progress * 100) / 100)
      if (progress == 100) {
        setIsCreatingInProgress(false)

        publishWorkoutHandler()
      }
    };

    return () => eventSource.close();
  }, [currentStep]);

  const createVideoData = (file) => {
    return new Promise((resolve) => {
      const _video = document.createElement("video");
      const _canvas = document.createElement("canvas");

      // this is important
      _video.autoplay = true;
      _video.muted = true;
      _video.src = URL.createObjectURL(file);

      _video.onloadeddata = () => {
        let ctx = _canvas.getContext("2d");

        _canvas.width = _video.videoWidth;
        _canvas.height = _video.videoHeight;

        ctx.drawImage(_video, 0, 0);

        _video.pause();

        return resolve({
          preview: _canvas.toDataURL("image/png/jpg"),
          video: file,
        });
      };
    });
  };

  const videoInputHandler = async (e) => {
    const noThumbnailImageBlob = await readTextFileAsBlob(NO_THUMBNAIL_IMAGE);
    setDefaultThumbnail(noThumbnailImageBlob);
    createVideoData(e.target.files[0])
      .then(({ video, preview }) => {
        setVideo(video);
        setVideoPreview(preview);
      })
      .catch((err) => console.log(err));
  };
  const thumbnailHandler = (e) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setThumbnailImage(reader.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    setVideoThumbnail(e?.target?.files[0]);
  };
  const thumbnailHandler2 = (e) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setThumbnailImage2(reader.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    setVideoThumbnail2(e?.target?.files[0]);
  };
  const inputHandler = (e) => {
    settingsHandler(e.target.name, e.target.value);
  };

  const settingsHandler = (name, value) => {
    let _value = value;

    if (name === "style") _value = value?.toUpperCase();
    if (name === 'prioritizeWhenWatchConnected') {
      _value = value === 'True' ? true : false
    }
    if (name == 'isFitnessTestVideo') {
      _value = value === 'Test' ? true : false
    }

    console.log('name', name, 'value', value, '_value', _value)

    setBaseWorkoutData({
      ...baseWorkoutData,
      [name]: _value,
    });
  };

  const partsChangeHandler = (type, value, partId, nodeId) => {
    let partIndex = parts.findIndex((part) => +part.partId === partId);
    let nodeIndex = parts[partIndex].nodes.findIndex(
      (node) => +node.nodeId === +nodeId
    );

    let _newPartsArray = [...parts];

    let _newNodesArray = [...parts[partIndex].nodes];

    switch (type) {
      case "timeStart":
      case "timeEnd":
        let timeRegExp = new RegExp(/^([0-9]{0,3})?:([0-9]{0,2})?$/);
        if (value.match(timeRegExp)) {
          _newNodesArray.splice(nodeIndex, 1, {
            ...parts[partIndex].nodes[nodeIndex],
            [type]: value === ":" ? "000:00" : value,
          });
        }
        break;
      default:
        throw new Error("Abd input type");
    }

    _newPartsArray.splice(partIndex, 1, {
      ...parts[partIndex],
      nodes: _newNodesArray,
    });

    setParts(_newPartsArray);
  };

  const targetHRHandler = ({ target: { value, id } }) => {
    const partIndex = parts.findIndex((part) => +part.partId === +id);

    let _newPartsArray = [...parts];

    let hrRegExp = new RegExp(/^([0-9]{0,3})?$/);

    if (value.match(hrRegExp)) {
      _newPartsArray.splice(partIndex, 1, {
        ...parts[partIndex],
        targetHR: value === "" ? "0" : value,
      });
    }

    setParts(_newPartsArray);
  };

  const partNameHandler = ({ target: { value, id } }) => {
    const partIndex = parts.findIndex((part) => +part.partId === +id);

    let _newPartsArray = [...parts];

    _newPartsArray.splice(partIndex, 1, {
      ...parts[partIndex],
      name: value,
    });
    setParts(_newPartsArray);
  };
  const partTagHandler = (name, title, id) => {
    const partIndex = parts.findIndex((part) => +part.partId === +id);

    let _newPartsArray = [...parts];

    _newPartsArray.splice(partIndex, 1, {
      ...parts[partIndex],
      tag: title,
    });

    setParts(_newPartsArray);
  }

  const addPart = () => {
    setParts([...parts, { ..._basePartData, partId: Date.now() }]);
  };

  const removePart = (id) => {
    setParts(parts.filter((p) => +p.partId !== +id));
  };

  const timeToSeconds = (time) => {
    const [minutes, seconds] = time.split(":");
    return +minutes * 60 + +seconds;
  };

  const getPreparedPartsData = () => {
    return parts.map(({ name, nodes, targetHR, tag }) => {
      return {
        name,
        nodes: nodes.map(({ timeStart, timeEnd, difficulty }) => {
          return {
            timeEnd: timeToSeconds(timeEnd),
            timeStart: timeToSeconds(timeStart),
            difficult: difficulty,
          };
        }),
        exercise: tag,
        targetHR: +targetHR,
      };
    });
  };

  const publishWorkoutHandler = async () => {

    await new WorkoutsAPI().updateWorkoutVideo({
      url: process.env.REACT_APP_VIDEO_SERVER + `public/${innerWorkoutId}/playlist/source.m3u8`,
      parts: getPreparedPartsData(),
    },
      innerWorkoutId
    ).then(res => {
      new WorkoutsAPI()
        .updateInformation(
          {
            customThumbnail: videoThumbnail ?? thumbnailImage,
            customHorizontalThumbnail: videoThumbnail2 ?? thumbnailImage2,
            difficulty: baseWorkoutData.difficulty,
            priority: baseWorkoutData.priority,
            workoutStyle: baseWorkoutData.style,
            parts: getPreparedPartsData(parts),
          },
          innerWorkoutId,
        )
    })
  }

  const uploadFile = async (id) => {
    if (!video) return
    const chunkSize = 2 * 1024 * 1024; // 2MB per chunk
    const totalChunks = Math.ceil(video.size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;

    const uploadNextChunk = async (id) => {
      if (chunkNumber < totalChunks) {
        const chunk = video.slice(start, end);
        const formData = new FormData();
        formData.append("video", chunk);
        formData.append("chunkNumber", chunkNumber);
        formData.append("totalChunks", totalChunks);
        formData.append("id", id);

        try {
          const response = await fetch(`${process.env.REACT_APP_VIDEO_SERVER}upload`, {
            method: "POST",
            body: formData,
          });
          const data = await response.json();

          if (!response.ok) {
            throw new Error(
              data.msg || `HTTP error! status: ${response.status}`
            );
          }

          // NB : We are subtracting 1 from the progressInPercentage to account errors in the progress bar, ping me for explaination
          const progressInPercentage = Math.max(
            0,
            Math.round(Number((chunkNumber + 1) * chunkProgress)) - 1
          );

          setProgressPercentage(progressInPercentage)
          if (chunkNumber === totalChunks - 1) {
            // This is the last chunk
            // NB : We are subtracting 1 from the progressInPercentage to account errors in the progress bar, ping me for explaination
            return data;
          }

          chunkNumber++;
          start = end;
          end = Math.min(start + chunkSize, video.size);
          return await uploadNextChunk(id);
        } catch (error) {
          throw error; // Re-throw the error to stop the upload process
        }
      } else {
      }
    };
    try {
      const finalResponse = await uploadNextChunk(id);
      if (finalResponse) {
        setProgressPercentage(0)
        setCurrentStep(3)
      }
    } catch (error) {

    }
  }
  const createWorkoutHandler = async (event) => {
    event.preventDefault();

    const isPartsValid = checkIsPartsValid();

    if (isPartsValid) {
      setIsCreatingInProgress(true);

      new WorkoutsAPI()
        .createWorkout(baseWorkoutData)
        .then((res) => {

          successAlert("Workout has been created! Please, upload video!");
          setInnerWorkoutId(res.data._id);
          setCurrentStep(2);
          var workoutID = res.data._id

          uploadFile(workoutID).then(res => {

            new WorkoutsAPI()
              .uploadWorkout({
                id: workoutID,
                parts: getPreparedPartsData()
              }).then(res => {
                axios.post(`${process.env.REACT_APP_VIDEO_SERVER}clear`, {
                  workoutID, userID: 'admin', parts: getPreparedPartsData()
                });
              })

          })


          /*-----------------Workouts Upload API-----------------*/

        })
        .catch((error) => {
          errorAlert(error);
          setIsCreatingInProgress(false);
          setCurrentStep(1);
        });

    }


  };

  const ProgressContent = () => {

    switch (currentStep) {
      case 1:
        return <CreatingWorkout />;
      case 2:
        return <UploadingWorkout progress={progressPercentage} />;
      case 3:
        return <AnalyzingWorkout progress={progressPercentage} />;
      default:
        return null;
    }

  };

  const checkIsPartsValid = () => {
    let isError = false;

    const invalidParts = parts.filter(
      ({ nodes }) =>
        nodes.filter(({ timeStart, timeEnd }) => {
          return (
            timeToSeconds(timeStart) <= 0 ||
            timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0
          );
        }).length
    );

    let invalidNodes = [];

    invalidParts.forEach(({ nodes }) => invalidNodes.push(...nodes));

    invalidNodes = invalidNodes.filter(
      ({ timeStart }) => timeToSeconds(timeStart) <= 0
    );

    if (invalidNodes.length > 1) {
      isError = true;
      errorAlert({ message: "Only one node can start at 00:00" });
    }

    invalidParts.forEach(({ nodes }) => invalidNodes.push(...nodes));

    invalidNodes = invalidNodes.filter(
      ({ timeStart, timeEnd }) =>
        timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0
    );

    if (invalidNodes.length > 1) {
      isError = true;
      errorAlert({ message: "Time end should be bigger then time start" });
    }

    return !isError;
  };
  console.log(defaultThumbnail, "DEFAULT");

  return (
    <>
      {isCreatingInProgress && (
        <dialog className={s.dialog} open={true}>
          <div className={s.dialog__content}>
            <ProgressContent />
            <small>
              Don't close this window until workout will be created!
            </small>
          </div>
        </dialog>
      )}
      <form onSubmit={createWorkoutHandler} className={s.wrapper}>
        <div className={s.wrapper__video}>
          <label className={s.wrapper__video__input}>
            <input
              name={"video_input"}
              multiple={false}
              onChange={videoInputHandler}
              type="file"
              accept="video/*"
            />

            {!video && <img src={videoFileIcon} alt={"addIcon"} />}
            {videoPreview && (
              <img
                src={videoPreview}
                alt={"preview"}
                className={s.wrapper__video__input__preview}
              />
            )}
          </label>
          <div className={s.wrapper__video__settings}>
            <Input
              required={true}
              onChange={inputHandler}
              className={s.wrapper__video__settings__title}
              name={"title"}
              placeholder={"Workout Title"}
            />
            <Multiselect
              placeholder={"Click to select equipment"}
              onSelect={settingsHandler}
              name={"equipments"}
              required={true}
              icon={sportEquipmentIcon}
              selectClassName={s.wrapper__video__settings__equipment}
              options={equipmentsList.map((e) => ({
                title: e.name,
                _id: e._id,
              }))}
            />
            <Select
              placeholder={"Click to select instructor"}
              icon={coachIcon}
              required={true}
              onSelect={settingsHandler}
              name={"instructor"}
              selectClassName={s.wrapper__video__settings__coach}
              options={instructorsList.map((i) => ({
                title: i.name,
                _id: i._id,
              }))}
            />
            <Multiselect
              placeholder={"Click to select body part"}
              onSelect={settingsHandler}
              name={"bodyPart"}
              required={true}
              icon={bodyPartIcon}
              selectClassName={s.wrapper__video__settings__body_part}
              options={bodyParts.map(({ title }, index) => ({
                title: title,
                _id: index,
              }))}
            />
            <Select
              placeholder={"Click to select workout style"}
              icon={workoutStyleIcon}
              required={true}
              onSelect={settingsHandler}
              name={"style"}
              selectClassName={s.wrapper__video__settings__workout_style}
              options={workoutStyles.map(({ title }, index) => ({
                title,
                _id: index,
              }))}
            />
            <Select
              icon={trueFalseIcon}
              placeholder={"Click to priorize a workout when a user has connected a watch"}
              onSelect={settingsHandler}
              name="prioritizeWhenWatchConnected"
              selectClassName={s.wrapper__video__settings__prioritize_watch_style}
              options={prioritizeWhenWatchConnectedOptions}
            />
            <fieldset className={s.wrapper__video__settings__difficulty}>
              <label htmlFor={"difficulty"}>
                Difficulty ({baseWorkoutData.difficulty})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.difficulty}
                onChange={inputHandler}
                name={"difficulty"}
                type={"range"}
                min={1}
                max={10}
                step={1}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__difficulty}>
              <label htmlFor={"priority"}>
                Priority ({baseWorkoutData.priority})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.priority}
                onChange={inputHandler}
                name={"priority"}
                type={"range"}
                min={1}
                max={10}
                step={1}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__calories}>
              <label htmlFor={"calory"}>
                Calories ({baseWorkoutData.calory})
              </label>
              <Input
                required={true}
                value={baseWorkoutData.calory}
                onChange={inputHandler}
                name={"calory"}
                type={"range"}
                min={200}
                max={1500}
                step={50}
              />
            </fieldset>
            <fieldset className={s.wrapper__video__settings__hr}>
              <label htmlFor={"hr"}>Target HR ({baseWorkoutData.hr})</label>
              <Input
                required={true}
                value={baseWorkoutData.hr}
                onChange={inputHandler}
                name={"hr"}
                type={"range"}
                min={60}
                max={180}
                step={5}
              />
            </fieldset>
            <Select
              icon={trueFalseIcon}
              placeholder={"Select workout mode"}
              onSelect={settingsHandler}
              name="isFitnessTestVideo"
              selectClassName={s.wrapper__video__settings__prioritize_watch_style}
              options={isFitnessTestVideo}
            />
          </div>
        </div>
        <div className={s.hasProfileUploadWrapper}>
          <div className={s.profilePhotoWrapper}>
            <div className={s.hasImage}>
              {thumbnailImage !== null && (
                <img src={thumbnailImage} alt={"Thumbnail_preview   "} />
              )}
            </div>
            <div>
              <div className={s.hasUploadButton}>
                <label htmlFor="uploadImage">Add Vertical Thumbnail</label>
                <input
                  type="file"
                  id={`uploadImage`}
                  accept=".png, .jpg"
                  onChange={thumbnailHandler}
                  required
                />
                {thumbnailImage && (
                  <p
                    onClick={() => (
                      setThumbnailImage(null), setVideoThumbnail(null)
                    )}
                    style={{ cursor: "pointer" }}
                  >
                    Remove Vertical Thumbnail
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={s.hasProfileUploadWrapper}>
          <div className={s.profilePhotoWrapper}>
            <div className={s.hasImage}>
              {thumbnailImage2 !== null && (
                <img src={thumbnailImage2} alt={"Thumbnail_preview   "} />
              )}
            </div>
            <div>
              <div className={s.hasUploadButton}>
                <label htmlFor="uploadImage">Add Horizontal Thumbnail</label>
                <input
                  type="file"
                  id={`uploadImage`}
                  accept=".png, .jpg"
                  onChange={thumbnailHandler2}
                  required
                />
                {thumbnailImage && (
                  <p
                    onClick={() => (
                      setThumbnailImage2(null), setVideoThumbnail2(null)
                    )}
                    style={{ cursor: "pointer" }}
                  >
                    Remove Horizontal Thumbnail
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {video && (
          <div className={s.wrapper__parts}>
            {parts.map(({ nodes, name, partId, targetHR }) => {
              const _fieldHandler = ({ target: { name, value } }) => {
                const [nodeId, type] = name.split("_");
                partsChangeHandler(type, value, partId, nodeId);
              };

              return (
                <div className={s.wrapper__parts__tile}>
                  <div className={s.wrapper__parts__tile__header}>
                    <Input
                      id={partId}
                      name={"part_name"}
                      value={name}
                      onChange={partNameHandler}
                    />
                    <Input
                      id={partId}
                      name={"targetHR"}
                      value={targetHR}
                      onChange={targetHRHandler}
                    />
                    <Select
                      placeholder={"Click to select tag"}
                      icon={coachIcon}
                      required={true}
                      onSelect={partTagHandler}
                      id={partId}
                      name={"tag"}
                      selectClassName={s.wrapper__video__settings__coach_2}
                      options={tagList.map((i) => ({
                        title: i.title,
                        _id: i._id,
                      }))}
                    />
                    {parts.length >= 2 && (
                      <Button
                        type={"button"}
                        onClick={removePart.bind(null, partId)}
                        title={"Remove part"}
                      />
                    )}
                    <Button
                      type={"button"}
                      onClick={addPart}
                      title={"Add new Part"}
                    />
                  </div>
                  <table className={s.wrapper__parts__tile__part} key={partId}>
                    <thead>
                      <tr>
                        <th>Difficulty</th>
                        <th>Time Start</th>
                        <th>Time End</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nodes.map(
                        ({ timeStart, timeEnd, difficulty, nodeId }) => {
                          return (
                            <tr
                              key={nodeId}
                              className={s.wrapper__parts__tile__part__nodes}
                            >
                              <td>{difficulty}</td>
                              <td width={"30%"}>
                                <Input
                                  onChange={_fieldHandler}
                                  name={`${nodeId}_timeStart`}
                                  value={timeStart}
                                />
                              </td>
                              <td width={"30%"}>
                                <Input
                                  onChange={_fieldHandler}
                                  name={`${nodeId}_timeEnd`}
                                  value={timeEnd}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        )}

        {/* == file image type end == */}
        <Button className={s.wrapper__submit} disabled={!video} type={"submit"}>
          Create workout
        </Button>
      </form>
    </>
  );
};

const CreatingWorkout = () => {
  return (
    <>
      <div className={s.dialog__content__header}>
        <p data-current-step={true}>1</p>
        <span />
        <p>2</p>
        <span />
        <p>3</p>
      </div>
      <div className={s.dialog__content__progress}>
        <p className={s.dialog__content__progress__first}>
          Creating workout data
        </p>
      </div>
    </>
  );
};

const UploadingWorkout = ({ progress }) => {
  return (
    <>
      <div className={s.dialog__content__header}>
        <p>1</p>
        <span />
        <p data-current-step={true}>2</p>
        <span />
        <p>3</p>

      </div>
      <div className={s.dialog__content__progress}>
        <p>Uploading video</p>
        <div className={s.dialog__content__progress__bar}>
          <span style={{ width: progress + "%" }} />
          <p>{progress}%</p>
        </div>
      </div>
    </>
  );
};

const AnalyzingWorkout = ({ progress }) => {
  return (
    <>
      <div className={s.dialog__content__header}>
        <p>1</p>
        <span />
        <p >2</p>
        <span />
        <p data-current-step={true}>3</p>

      </div>
      <div className={s.dialog__content__progress}>
        <p>Analyzing video</p>
        <div className={s.dialog__content__progress__bar}>
          <span style={{ width: progress + "%" }} />
          <p>{progress}%</p>
        </div>
      </div>
    </>
  );
};

